import React, { lazy, Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import APP_ROUTES, {
  DASHBOARD_APP_ROUTES,
  LISTENER_APP_ROUTES as LISTENER,
  OPEN_APP_ROUTES,
  PODCASTER_APP_ROUTES as PODCASTER,
  PODCASTER_APP_ROUTES,
} from '../constant/appRoute';
import PrivateRoute from '../components/auth/PrivateRoute';
import FullPageLoader from '../components/Loader/FullPageLoader';
import DashboardLayout from '../pages/layout/Dashboard.Layout';
import PodcastPage from '../pages/Dashboard/Sections/Pages/PodcastManagement/ManagementPodcast/ManagementPodcast.Page';
import NoNetwork from './NoNetwork';
import EmptyComponent from '../components/Empty/Empty.Component';

const ChangePassword = lazy(() => import('../components/Login/ChangePassword'));
const NotFoundPage = lazy(() => import('../pages/Dashboard/Sections/Pages/404/404.Pge'));
const PricingPage = lazy(() => import('../pages/PricingPage/index'));
const FavoritePage = lazy(() => import('../pages/listener/Favorite/FavoritePage'));
const CreateProfile = lazy(() => import('../pages/Profile/CreateProfile'));

const DownloadPage = lazy(() => import('../pages/listener/Download/DownloadPage'));
const RecentPage = lazy(() => import('../pages/Dashboard/Sections/Pages/Recent/Recent.Page'));
const MyPodcasts = lazy(
  () => import('../pages/Dashboard/Sections/Pages/PodcastManagement/MyPodcast/MyPodcast.Page')
);

const AnalyticsPage = lazy(() => import('../pages/podcaster/Analytics/AnalyticsPage'));

const EditProfile = lazy(() => import('../pages/podcaster/Profile/EditProfile'));

const SubscriptionPage = lazy(() => import('../pages/listener/Subscription/SubscriptionPage'));

const PodcastDetail = lazy(() => import('../pages/listener/PodcastDetail/PodcastDetail'));
const EpisodeDetail = lazy(() => import('../pages/listener/EpisodeDetail/EpisodeDetail'));

const PodcastDetailPage = lazy(
  () =>
    import('../pages/Dashboard/Sections/Pages/PodcastManagement/PodcastDetail/PodcastDetail.Page')
);

const EpisodePage = lazy(() => import('../pages/podcaster/Episode/EpisodePage'));

const DashboardRouters = () => (
  <Suspense fallback={<FullPageLoader isScreenExist />}>
    <Routes>
      {/* Main dashboard route with layout */}
      <Route
        path={DASHBOARD_APP_ROUTES.DASHBOARD}
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        {/* Dashboard home page */}
        <Route
          path={DASHBOARD_APP_ROUTES.DISCOVER}
          element={
            <PrivateRoute>
              <div>DASHBOARD PAGE</div>
            </PrivateRoute>
          }
        />

        <Route
          path={DASHBOARD_APP_ROUTES.SETTINGS}
          element={
            <PrivateRoute>
              <div>SETTINGS PAGE</div>
            </PrivateRoute>
          }
        />

        {/* Profile Management Routes */}
        <Route path={PODCASTER.CREATE_PROFILE} element={<CreateProfile />} />
        <Route path={PODCASTER.PROFILE} element={<EditProfile />} />

        {/* Listener-specific Routes */}
        <Route path={LISTENER.FAVORITES} element={<FavoritePage />} />
        <Route path={LISTENER.DOWNLOADS} element={<DownloadPage />} />

        {/* Password Update Route */}
        <Route path={OPEN_APP_ROUTES.PASSWORD_UPDATE} element={<ChangePassword />} />

        {/* Pricing Route */}
        <Route path={DASHBOARD_APP_ROUTES.PRICING} element={<PricingPage />} />

        {/* Podcast Management */}
        <Route path={DASHBOARD_APP_ROUTES.PODCAST_CREATOR_DETAIL} element={<Outlet />}>
          {/* Main podcast detail page */}
          <Route index element={<PodcastDetailPage />} />

          {/* Nested podcast routes for specific slug */}
          <Route path={DASHBOARD_APP_ROUTES.PODCAST_SLUG} element={<Outlet />}>
            {/* Podcast detail as the index route */}
            <Route index element={<PodcastDetailPage />} />

            {/* Nested route for specific episode */}
            <Route path={PODCASTER.EPISODE_ID} element={<Outlet />}>
              {/* Episode detail view */}
              <Route
                index
                element={
                  <EmptyComponent
                    title='Not Found Episode'
                    desc='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
                  />
                }
              />

              {/* Edit episode */}
              <Route path={APP_ROUTES.EDIT} element={<EpisodePage />} />
            </Route>
          </Route>
        </Route>

        {/* Additional Podcast Management */}
        <Route path={PODCASTER_APP_ROUTES.ADD_PODCAST} element={<PodcastPage />} />
        <Route
          path={`${DASHBOARD_APP_ROUTES.EDIT_PODCAST}/${DASHBOARD_APP_ROUTES.PODCAST_SLUG}`}
          element={<PodcastPage />}
        />
        <Route path={DASHBOARD_APP_ROUTES.MY_PODCAST} element={<MyPodcasts pageType='main' />} />

        {/* Analytics and Subscriptions */}
        <Route path={DASHBOARD_APP_ROUTES.RECENT} element={<RecentPage />} />
        <Route path={DASHBOARD_APP_ROUTES.DRAFT} element={<MyPodcasts pageType='draft' />} />
        <Route path={DASHBOARD_APP_ROUTES.ANALYTICS} element={<AnalyticsPage />} />
        <Route
          path={`${DASHBOARD_APP_ROUTES.ANALYTICS}/${DASHBOARD_APP_ROUTES.REVENUE}`}
          element={<AnalyticsPage />}
        />

        {/* Subscriptions Management */}
        <Route
          path={`${DASHBOARD_APP_ROUTES.SUBSCRIPTIONS}/${DASHBOARD_APP_ROUTES.PODCASTS}`}
          element={<SubscriptionPage />}
        />
        <Route
          path={`${DASHBOARD_APP_ROUTES.SUBSCRIPTIONS}/${DASHBOARD_APP_ROUTES.PODCASTERS}`}
          element={<SubscriptionPage />}
        />

        {/* Podcast Episode Routes */}
        <Route
          path={`${DASHBOARD_APP_ROUTES.PODCAST_SLUG}/${PODCASTER.ADD_EPISODE}`}
          element={<EpisodePage />}
        />
        <Route path={DASHBOARD_APP_ROUTES.PODCAST_DETAIL} element={<Outlet />}>
          <Route path={DASHBOARD_APP_ROUTES.PODCAST_SLUG} element={<PodcastDetail />} />
          <Route
            path={`${OPEN_APP_ROUTES.PODCAST_SLUG}/${OPEN_APP_ROUTES.EPISODE_UUID}`}
            element={<EpisodeDetail />}
          />
        </Route>
      </Route>
    </Routes>
  </Suspense>
);

export default DashboardRouters;
