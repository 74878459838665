import {
  joinAsAdvertiser,
  joinAsPodcaster,
  loginService as podcasterLoginService,
} from '../../services/podcaster/Auth';
import { loginService as listenerLoginService } from '../../services/listener/Auth';
import { loginService as adminLoginService } from '../../services/admin/Auth';
import { advertiserLoginService } from '../../services/Advertiser/Auth';

import { setDataInLocalStorage, showToastMessage } from '../../utils';
import {
  ADMIN_LOGIN,
  ADVERTISER_LOGIN,
  GET_USER_DETAIL,
  LISTENER_LOGIN,
  PODCASTER_LOGIN,
  UPDATE_USER_DETAIL,
  USER_LOGOUT,
} from '../constants/user';
import { APP_HOST, TOASTER_STATUS } from '../../constant';

import { UserDispatch, UserGetState } from '../types/user';
import { ILogin } from '../../types/auth';
import {
  ActivePlanUuidAndEndDate,
  IMonetizePodcast,
  IUpdateCount,
  IUpdateUser,
  IUserPurchasePlan,
} from '../../types';
import { getUserDetails } from '../../services/user/User';
import { ADVERTISER_APP_ROUTES, DASHBOARD_APP_ROUTES } from '../../constant/appRoute';
import { getEditorEpisodeList } from './podcaster/audioEditor';

// Podcaster login Action
export const podCasterLoginAction =
  (
    loginData: ILogin,
    handleLoading: (value: boolean) => void,
    onContinue: (
      userProfile: boolean,
      userPurchasedPlan: boolean,
      userCreatedPodcast: boolean
    ) => void,
    handleResendEmail: (email: string) => void,
    doCheckEmail: CallableFunction
  ) =>
  async (dispatch: UserDispatch, getState: UserGetState) => {
    try {
      const response = await podcasterLoginService(loginData);
      if (response.data.success) {
        setDataInLocalStorage(response.data.result);
        // localStorage.setItem('host', APP_HOST.PODCASTER);
        dispatch({
          type: PODCASTER_LOGIN,
          payload: {
            ...getState().user,
            uuid: response.data.result.uuid,
            email: response.data.result.email,
            roles: response.data.result.roles,
            userProfileCompleted: response.data.result.userProfileCompleted,
            userPurchasedPlan: response.data.result.userPurchasedPlan,
            createdPodcast: response.data.result?.createdPodcast,
          },
        });
        onContinue(
          response.data.result.userProfileCompleted,
          response.data.result.userPurchasedPlan,
          response.data.result?.createdPodcast
        );
      } else {
        showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
      }
    } catch (error: any) {
      if (error?.response?.data?.result?.errorCode === '4040104') {
        handleResendEmail(loginData.email);
      } else if (error?.response?.data?.result?.errorCode === '4040105') {
        doCheckEmail();
      } else {
        showToastMessage(
          TOASTER_STATUS.ERROR,
          error?.response?.data?.result?.errorMessage ?? error.message
        );
      }
    } finally {
      handleLoading(false);
    }
  };

// Get user detail
export function getUserDetailAction(host: string, handleLoading: CallableFunction = () => {}) {
  return async (dispatch: UserDispatch, getState: UserGetState) => {
    try {
      const response = await getUserDetails(host);

      if (host === APP_HOST.PODCASTER) {
        dispatch(getEditorEpisodeList());
      }

      if (response.data.success) {
        dispatch({
          type: GET_USER_DETAIL,
          payload: {
            ...getState().user,
            ...response.data.result,
          },
        });

        if (
          !response.data?.result?.userProfileCompleted &&
          !window.location.href.includes('create-profile')
        ) {
          if (host === APP_HOST.PODCASTER) {
            window.location.replace(
              `/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.CREATE_PROFILE}`
            );
          } else if (host === APP_HOST.LISTENER) {
            window.location.replace(`/${DASHBOARD_APP_ROUTES.DASHBOARD}`);
          }
          // TODO REMOVE UNUSED ADVERTISER
          // else if (host === APP_HOST.ADVERTISER) {
          //   window.location.replace(
          //     `/${ADVERTISER_APP_ROUTES.ROOT}/${ADVERTISER_APP_ROUTES.CREATE_PROFILE}`
          //   );
          // }
        }
      } else {
        showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
      }
    } catch (error: any) {
      showToastMessage(TOASTER_STATUS.ERROR, error.message);
    } finally {
      handleLoading(false);
    }
  };
}

// Update user detail
type updateAction =
  | IUpdateUser
  | IMonetizePodcast
  | IUpdateCount
  | ActivePlanUuidAndEndDate
  | IUserPurchasePlan;

export function updateUserDetailAction(data: updateAction) {
  return async (dispatch: UserDispatch, getState: UserGetState) => {
    dispatch({
      type: UPDATE_USER_DETAIL,
      payload: {
        ...getState().user,
        ...data,
      },
    });
  };
}

// Listener login Action
export const listenerLoginAction =
  (
    loginData: ILogin,
    handleLoading: (value: boolean) => void,
    onContinue: (userProfile: boolean) => void,
    handleResendEmail: (email: string) => void
  ) =>
  async (dispatch: UserDispatch, getState: UserGetState) => {
    try {
      const response = await listenerLoginService(loginData);
      if (response.data.success) {
        setDataInLocalStorage(response.data.result);
        localStorage.setItem('host', APP_HOST.LISTENER);
        dispatch({
          type: LISTENER_LOGIN,
          payload: {
            ...getState().user,
            uuid: response.data.result.uuid,
            email: response.data.result.email,
            roles: response.data.result.roles,
            userProfileCompleted: response.data.result.userProfileCompleted,
            createdPodcast: response.data.result?.createdPodcast,
          },
        });
        onContinue(response.data.result.userProfileCompleted);
      } else {
        showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
      }
    } catch (error: any) {
      if (error?.response?.data?.result?.errorCode === '4040104') {
        handleResendEmail(loginData.email);
      } else {
        showToastMessage(
          TOASTER_STATUS.ERROR,
          error?.response?.data?.result?.errorMessage ?? error.message
        );
      }
    } finally {
      handleLoading(false);
    }
  };

// Admin login Action
export function adminLoginAction(
  loginData: ILogin,
  handleLoading: (value: boolean) => void,
  onContinue: () => void
) {
  return async (dispatch: UserDispatch, getState: UserGetState) => {
    try {
      const response = await adminLoginService(loginData);
      if (response?.data?.success) {
        setDataInLocalStorage(response?.data?.result);
        localStorage.setItem('host', APP_HOST.ADMIN);
        dispatch({
          type: ADMIN_LOGIN,
          payload: {
            ...getState().user,
            uuid: response?.data?.result?.uuid,
            email: response?.data?.result?.email,
            roles: response?.data?.result?.roles,
            userProfileCompleted: response?.data?.result?.userProfileCompleted,
          },
        });
        onContinue();
      } else {
        showToastMessage(TOASTER_STATUS.ERROR, response?.data?.error?.txt);
      }
    } catch (error: any) {
      showToastMessage(
        TOASTER_STATUS.ERROR,
        error?.response?.data?.result?.errorMessage ?? error?.message
      );
    } finally {
      handleLoading(false);
    }
  };
}

// Admin login Action
export const advertiserLoginAction =
  (
    loginData: ILogin,
    handleLoading: CallableFunction,
    onContinue: CallableFunction,
    handleResendEmail: (email: string) => void,
    doCheckEmail: CallableFunction
  ) =>
  async (dispatch: UserDispatch, getState: UserGetState) => {
    try {
      const response = await advertiserLoginService(loginData);
      if (response?.data?.success) {
        setDataInLocalStorage(response?.data?.result);
        localStorage.setItem('host', APP_HOST.ADVERTISER);
        dispatch({
          type: ADVERTISER_LOGIN,
          payload: {
            ...getState().user,
            uuid: response?.data?.result?.uuid,
            email: response?.data?.result?.email,
            roles: response?.data?.result?.roles,
            userProfileCompleted: response?.data?.result?.userProfileCompleted,
            createdPodcast: response.data.result?.createdPodcast,
          },
        });
        onContinue(response?.data?.result?.userProfileCompleted);
      } else {
        showToastMessage(TOASTER_STATUS.ERROR, response?.data?.error?.txt);
      }
    } catch (error: any) {
      if (error?.response?.data?.result?.errorCode === '4040104') {
        handleResendEmail(loginData.email);
      } else if (error?.response?.data?.result?.errorCode === '4040105') {
        doCheckEmail();
      } else {
        showToastMessage(
          TOASTER_STATUS.ERROR,
          error?.response?.data?.result?.errorMessage ?? error.message
        );
      }
    } finally {
      handleLoading(false);
    }
  };

export function userLogout() {
  return async (dispatch: UserDispatch) =>
    dispatch({
      type: USER_LOGOUT,
    });
}

export const joinAsAdvertiserAction =
  (email: string, handleLoading: (value: boolean) => void, handleAdvertiser: () => void) =>
  async (dispatch: UserDispatch, getState: UserGetState) => {
    try {
      const response = await joinAsAdvertiser(email);
      if (response.data.success) {
        setDataInLocalStorage(response.data.result);
        localStorage.setItem('host', APP_HOST.ADVERTISER);
        dispatch({
          type: ADVERTISER_LOGIN,
          payload: {
            ...getState().user,
            uuid: response.data.result.uuid,
            email: response.data.result.email,
            roles: response.data.result.roles,
            userProfileCompleted: response.data.result.userProfileCompleted,
            userPurchasedPlan: response.data.result.userPurchasedPlan,
          },
        });
        handleAdvertiser();
      } else {
        showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
      }
    } catch (error: any) {
      showToastMessage(
        TOASTER_STATUS.ERROR,
        error?.response?.data?.result?.errorMessage ?? error.message
      );
    } finally {
      handleLoading(false);
    }
  };

export const joinAsPodcasterAction =
  (
    email: string,
    handleLoading: (value: boolean) => void,
    handlePodcaster: (createdPodcast: boolean) => void
  ) =>
  async (dispatch: UserDispatch, getState: UserGetState) => {
    try {
      const response = await joinAsPodcaster(email);
      if (response.data.success) {
        setDataInLocalStorage(response.data.result);
        localStorage.setItem('host', APP_HOST.PODCASTER);
        dispatch({
          type: PODCASTER_LOGIN,
          payload: {
            ...getState().user,
            uuid: response.data.result.uuid,
            email: response.data.result.email,
            roles: response.data.result.roles,
            userProfileCompleted: response.data.result.userProfileCompleted,
            userPurchasedPlan: response.data.result.userPurchasedPlan,
            createdPodcast: response.data.result?.createdPodcast,
          },
        });
        handlePodcaster(response.data.result.createdPodcast);
      } else {
        showToastMessage(TOASTER_STATUS.ERROR, response.data.error.txt);
      }
    } catch (error: any) {
      showToastMessage(
        TOASTER_STATUS.ERROR,
        error?.response?.data?.result?.errorMessage ?? error.message
      );
    } finally {
      handleLoading(false);
    }
  };
