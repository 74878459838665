import React, { FC, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import DragDropFile from '../../DragAndDrop/DragDropFile';
import IconButtonWrapper from '../../IconButtonWrapper';

import File from '../../../assets/svg/File';
import RemoveIcon from '../../../assets/svg/RemoveIcon';

import './index.scss';
import IconComponent from '../../Icon/Icon.Component';
import { UploadFileWrapperType } from './UploadFileWrapper.Types';

const UploadFileWrapper: FC<UploadFileWrapperType> = ({
  columns,
  accept,
  handleFile,
  maxFileSize,
  sizeIn,
  height,
  width,
  label,
  value,
  name,
  handleRemove,
  error,
  touched,
  disabled,
  required,
  className,
}) => {
  const uploadFileRef: any = useRef<HTMLDivElement>(null);

  const { isSubmitting } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && touched && error) {
      uploadFileRef?.current?.focus();
    }
  }, [isSubmitting]);

  return (
    <div
      // tabIndex={1}
      ref={uploadFileRef}
      className={`${columns} form-style upload-file-wrapper m-b-5`}
      id={name}
    >
      <div className='h-100 mb-1 position-relative'>
        <div className='form-field-label'>
          {label}
          {required && <span className='required red'>*</span>}
        </div>
        {value ? (
          <div>
            <div className='position-relative h-100'>
              {!disabled && (
                <IconButtonWrapper
                  IconName={RemoveIcon}
                  onClick={handleRemove}
                  className='close-banner'
                />
              )}
              <img
                src={typeof value === 'string' ? value : URL.createObjectURL(value)}
                alt={name}
                className={`image-view ${className}`}
              />
            </div>
          </div>
        ) : (
          <div className=''>
            <DragDropFile
              accept={accept}
              handleFile={handleFile}
              icon={<IconComponent name='Upload' path='dashboard/Upload.svg' />}
              maxFileSize={maxFileSize}
              sizeIn={sizeIn}
              height={height}
              width={width}
              disabled={disabled}
              className={className}
            />
          </div>
        )}
        {error && touched && (
          <span className='text-danger position-absolute fs-6 fw-normal error'>{error}</span>
        )}
      </div>
    </div>
  );
};

export default UploadFileWrapper;
