import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import AppContext from '../../context/App/AppContext';
import LeftPanelDashboard from '../Dashboard/Sections/LeftPanel/LeftPanel.Section';
import { USER_ROLE } from '../../constant';
import { getUserDetailAction } from '../../redux/actions/user';
import HeaderDashboard from '../Dashboard/Sections/Header/Header.Section';

interface IProps {
  getUserDetailAction: (host: string, handleLoading: CallableFunction) => void;
}

const DashboardLayout: React.FC<IProps> = ({ getUserDetailAction }) => {
  const host = localStorage.getItem('host');
  const token = localStorage.getItem('accessToken');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLoading = (value: boolean) => setIsLoading(value);
  useEffect(() => {
    if (token && host) {
      handleLoading(true);
      getUserDetailAction(host, handleLoading);
    }
  }, []);

  return (
    <main className='page-wrapper rbt-dashboard-page'>
      <AppContext>
        <div className='rbt-panel-wrapper'>
          {/* <PopupMobileMenu/> */}
          <LeftPanelDashboard role={USER_ROLE.PODCASTER} />
          <div className='rbt-main-content mr--0 mb--0'>
            <HeaderDashboard />
            <div className='rbt-daynamic-page-content center-width'>
              <div className='rbt-dashboard-content'>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </AppContext>
    </main>
  );
};

const mapDispatchToProps = {
  getUserDetailAction,
};

export default connect(null, mapDispatchToProps)(DashboardLayout);
