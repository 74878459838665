import React from 'react';

const DeleteIconPop = () => (
  <svg
    width='100'
    height='100'
    viewBox='0 0 100 100'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='img-fluid d-block mx-auto'
  >
    <rect width='100' height='100' rx='16' fill='#FD6060' fillOpacity='0.24' />
    <path
      d='M65.556 38.8887C64.9666 38.8887 64.4014 39.1228 63.9846 39.5395C63.5679 39.9563 63.3338 40.5215 63.3338 41.1109V65.9798C63.27 67.1035 62.7648 68.1566 61.9282 68.9095C61.0916 69.6625 59.9913 70.0543 58.8671 69.9998H41.1338C40.0095 70.0543 38.9093 69.6625 38.0727 68.9095C37.2361 68.1566 36.7308 67.1035 36.6671 65.9798V41.1109C36.6671 40.5215 36.433 39.9563 36.0162 39.5395C35.5995 39.1228 35.0342 38.8887 34.4449 38.8887C33.8555 38.8887 33.2903 39.1228 32.8735 39.5395C32.4568 39.9563 32.2227 40.5215 32.2227 41.1109V65.9798C32.2861 68.2825 33.2595 70.4663 34.9297 72.0528C36.5999 73.6393 38.8308 74.4992 41.1338 74.4442H58.8671C61.17 74.4992 63.4009 73.6393 65.0711 72.0528C66.7414 70.4663 67.7148 68.2825 67.7782 65.9798V41.1109C67.7782 40.5215 67.5441 39.9563 67.1273 39.5395C66.7106 39.1228 66.1454 38.8887 65.556 38.8887Z'
      fill='#FF5454'
    />
    <path
      d='M67.7778 32.2221H58.8889V27.7776C58.8889 27.1883 58.6548 26.623 58.238 26.2063C57.8213 25.7895 57.256 25.5554 56.6667 25.5554H43.3333C42.744 25.5554 42.1787 25.7895 41.762 26.2063C41.3452 26.623 41.1111 27.1883 41.1111 27.7776V32.2221H32.2222C31.6329 32.2221 31.0676 32.4562 30.6509 32.873C30.2341 33.2897 30 33.8549 30 34.4443C30 35.0337 30.2341 35.5989 30.6509 36.0157C31.0676 36.4324 31.6329 36.6665 32.2222 36.6665H67.7778C68.3671 36.6665 68.9324 36.4324 69.3491 36.0157C69.7659 35.5989 70 35.0337 70 34.4443C70 33.8549 69.7659 33.2897 69.3491 32.873C68.9324 32.4562 68.3671 32.2221 67.7778 32.2221ZM45.5556 32.2221V29.9999H54.4444V32.2221H45.5556Z'
      fill='#FF5454'
    />
    <path
      d='M47.7784 61.1108V45.5552C47.7784 44.9659 47.5443 44.4006 47.1276 43.9839C46.7108 43.5671 46.1456 43.333 45.5562 43.333C44.9668 43.333 44.4016 43.5671 43.9849 43.9839C43.5681 44.4006 43.334 44.9659 43.334 45.5552V61.1108C43.334 61.7002 43.5681 62.2654 43.9849 62.6821C44.4016 63.0989 44.9668 63.333 45.5562 63.333C46.1456 63.333 46.7108 63.0989 47.1276 62.6821C47.5443 62.2654 47.7784 61.7002 47.7784 61.1108Z'
      fill='#FF5454'
    />
    <path
      d='M56.6671 61.1108V45.5552C56.6671 44.9659 56.433 44.4006 56.0162 43.9839C55.5995 43.5671 55.0342 43.333 54.4449 43.333C53.8555 43.333 53.2903 43.5671 52.8735 43.9839C52.4568 44.4006 52.2227 44.9659 52.2227 45.5552V61.1108C52.2227 61.7002 52.4568 62.2654 52.8735 62.6821C53.2903 63.0989 53.8555 63.333 54.4449 63.333C55.0342 63.333 55.5995 63.0989 56.0162 62.6821C56.433 62.2654 56.6671 61.7002 56.6671 61.1108Z'
      fill='#FF5454'
    />
  </svg>
);

export default DeleteIconPop;
