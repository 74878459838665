import React from 'react';

const ColorStart = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2993_19094)'>
      <path
        d='M19.4693 7.15354L13.321 6.21438L10.5651 0.344375C10.3593 -0.0939583 9.64012 -0.0939583 9.43429 0.344375L6.67929 6.21438L0.530958 7.15354C0.0259577 7.23104 -0.175709 7.84521 0.178458 8.20771L4.64512 12.786L3.58929 19.2585C3.50512 19.7727 4.05429 20.1585 4.50846 19.906L10.0001 16.871L15.4918 19.9069C15.9418 20.1569 16.496 19.7785 16.411 19.2594L15.3551 12.7869L19.8218 8.20854C20.176 7.84521 19.9735 7.23104 19.4693 7.15354Z'
        fill='#FFC107'
      />
    </g>
    <defs>
      <clipPath id='clip0_2993_19094'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default ColorStart;
