import { Link } from 'react-router-dom';
import React from 'react';
import { useAppContext } from '../../../../context/App/AppContext';
import DarkAvatar from '../../../../assets/svg/dashboard/dark/avatar.svg';
import LightAvatar from '../../../../assets/svg/dashboard/light/avatar.svg';
import Plus from '../../../../assets/svg/dashboard/plus.svg';
import IconComponent from '../../../../components/Icon/Icon.Component';
import UserMenuComponent from './Components/UserMenu/UserMenu.Component';

const HeaderDashboard = () => {
  const { isLightTheme, activeMobileMenu, setActiveMobileMenu } = useAppContext();
  return (
    <header className='rbt-dashboard-header rainbow-header header-default header-left-align rbt-fluid-header'>
      <div className='position-relative'>
        <div className='row align-items-center'>
          <div className='col-lg-2 col-md-6 col-7' />

          <div className='col-lg-10 col-md-6 col-5'>
            <div className='header-right'>
              <IconComponent
                name='Notification'
                path={
                  isLightTheme
                    ? `dashboard/light/Notification.svg`
                    : `dashboard/light/Notification.svg`
                }
              />
              <div className='header-btn d-none d-md-block'>
                <Link
                  className='btn-default bg-gradient-secondary btn-small d-flex align-items-center'
                  to='/dashboard/podcast-add'
                >
                  <img src={Plus} alt='avatar' className='mr--5' /> New Podcast
                </Link>
              </div>

              <div className='mobile-menu-bar mr--10 ml--10 d-block d-lg-none'>
                <div className='hamberger'>
                  <button
                    type='button'
                    className='hamberger-button'
                    onClick={() => setActiveMobileMenu(!activeMobileMenu)}
                  >
                    <i className='feather-menu' />
                  </button>
                </div>
              </div>

              {/* <GridMenu ToolsData={ToolsData}/> */}

              <div className='account-access rbt-user-wrapper right-align-dropdown'>
                <div className='rbt-user'>
                  {isLightTheme ? (
                    <img src={DarkAvatar} alt='avatar' />
                  ) : (
                    <img src={LightAvatar} alt='avatar' />
                  )}
                </div>
                <div className='rbt-user-menu-list-wrapper'>
                  <UserMenuComponent />
                </div>
              </div>

              {/* <div className={`expand-btn-grp `}> */}
              {/*    <button */}
              {/*        type='button' */}
              {/*        className={`bg-solid-primary popup-dashboardright-btn ${ */}
              {/*            !rightBar ? "collapsed" : "" */}
              {/*        }`} */}
              {/*        onClick={() => setRightBar(!rightBar)} */}
              {/*    > */}
              {/*        wfff<i className='feather-sidebar right'/> */}
              {/*    </button> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderDashboard;
