import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import DarkAvatar from '../../../../../../assets/svg/dashboard/dark/avatar.svg';
import { useAppContext } from '../../../../../../context/App/AppContext';
import IconComponent from '../../../../../../components/Icon/Icon.Component';
import { IUser } from '../../../../../../types';
import { IState } from '../../../../../../redux/types';
import { showAudioPlayer } from '../../../../../../redux/selectors/audioPlayer';
import { getEditorEpisodeCount } from '../../../../../../redux/selectors/podcaster/audioEditor';
import { getUserDetailAction } from '../../../../../../redux/actions/user';

interface IProps {
  user: IUser;
}

const UserMenuComponent: React.FC<IProps> = ({ user }) => {
  const { isLightTheme } = useAppContext();
  return (
    <div className='inner'>
      <div className='rbt-admin-profile'>
        <div className='admin-thumbnail'>
          {user?.profilePhotoUrl! ? (
            <img
              src={user?.profilePhotoUrl}
              alt={user.firstName}
              className='img-fluid rounded me-3 flex-none'
            />
          ) : (
            <img src={DarkAvatar} alt='avatar' />
          )}
        </div>
        <div className='admin-info'>
          <span className='name'>{`${user.firstName}  ${user.lastName}`}</span>
          <span className='mt--3'>{user.email}</span>
        </div>
      </div>
      <hr className='mt--10 mb--10' />
      <ul className='user-list-wrapper user-nav'>
        <li>
          <Link to='/dashboard/profile'>
            <IconComponent
              name='Profile'
              path={isLightTheme ? `dashboard/light/Profile.svg` : `dashboard/light/Profile.svg`}
            />
            <span>View Profile</span>
          </Link>
        </li>
        <li>
          <Link to='/dashboard/password/update'>
            <IconComponent
              name='Password'
              path={isLightTheme ? `dashboard/light/Password.svg` : `dashboard/light/Password.svg`}
            />
            <span>Change Password</span>
          </Link>
        </li>
        <li>
          <Link to='/dashboard/settings'>
            <IconComponent
              name='Settings'
              path={isLightTheme ? `dashboard/light/Settings.svg` : `dashboard/light/Settings.svg`}
            />
            <span>Settings</span>
          </Link>
        </li>
      </ul>
      <hr className='mt--10 mb--10' />
      <ul className='user-list-wrapper'>
        <li>
          <button type='button'>
            <IconComponent
              name='Logout'
              path={isLightTheme ? `dashboard/light/Logout.svg` : `dashboard/light/Logout.svg`}
            />
            <span>Logout</span>
          </button>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  user: state.user,
  hasAudioPlayer: showAudioPlayer(state),
  editorEpisodeCount: getEditorEpisodeCount(state),
});

const mapDispatchToProps = {
  getUserDetailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenuComponent);
