import React, { type FC } from 'react';
import FormikFieldWrapper from '../../../../../../../../../components/FormikWrapper/FormikFieldWrapper';
import PodcastSlugUrl from '../../../../../../../../podcaster/Podcast/PodcastSlugUrl';
import FormikSelectWrapper from '../../../../../../../../../components/FormikWrapper/FormikSelectWrapper';
import { getCountries, getLanguages } from '../../../../../../../../../utils';
import FormikTextAreaWrapper from '../../../../../../../../../components/FormikWrapper/FormikTextAreaWrapper';
import FormikTagWrapper from '../../../../../../../../../components/FormikWrapper/FormikTagWrapper';
import { PodcastDetailsTypes } from './PodcastDetails.Types';

const PodcastDetailsStep: FC<PodcastDetailsTypes> = ({
  setTitleIntoSlug,
  slug,
  setSlug,
  initialPodcast,
  options,
}) => (
  <>
    <div className='d-flex row from-group-wrapper'>
      <div className='col-lg-6 podcast-select-column'>
        <FormikFieldWrapper
          label='Title'
          name='podcastTitle'
          type='text'
          placeholder='Enter Title'
          // disabled={
          //   initialPodcast.status.toLowerCase() === PODCAST_STATUS.PUBLISH
          // }
          handleChange={setTitleIntoSlug}
          required
          validateOnChange={{ length: true }}
        />
      </div>
      <div className='col-lg-6 podcast-select-column'>
        <PodcastSlugUrl
          slug={slug}
          setSlug={setSlug}
          initialSlug={initialPodcast.slugUrl}
          // disabled={
          //   initialPodcast.status.toLowerCase() === PODCAST_STATUS.PUBLISH
          // }
        />
      </div>
    </div>
    <div className='podcast-select'>
      <div className='podcast-select-column'>
        <FormikTextAreaWrapper
          label='Description'
          name='description'
          placeholder='Type Description...'
          required
        />
      </div>
    </div>
    <div className='dropdown-group row podcast-select from-group-wrapper'>
      <div className='col-lg-6 podcast-select-column'>
        <FormikSelectWrapper
          name='podcastCategory'
          label='Category'
          options={options.categories}
          selectSuggestion='Select Category'
          className='mb-0'
          // disabled={
          //   initialPodcast.status.toLowerCase() ===
          //   PODCAST_STATUS.PUBLISH
          // }
          required
        />
      </div>
      <div className='col-lg-6 podcast-select-column'>
        <FormikSelectWrapper
          name='podcastType'
          label='Type'
          options={options.types}
          selectSuggestion='Select Type'
          className='mb-0'
          // disabled={
          //   initialPodcast.status.toLowerCase() ===
          //   PODCAST_STATUS.PUBLISH
          // }
          required
        />
      </div>
    </div>
    <div className='dropdown-group row podcast-select from-group-wrapper'>
      <div className='col-lg-6 podcast-select-column'>
        <FormikSelectWrapper
          name='country'
          label='Country of Origin'
          options={getCountries()}
          selectSuggestion='Select Country'
          className='mb-0'
          // disabled={
          //   initialPodcast.status.toLowerCase() ===
          //   PODCAST_STATUS.PUBLISH
          // }
          required
        />
      </div>
      <div className='col-lg-6 podcast-select-column'>
        <FormikSelectWrapper
          name='language'
          label='Language'
          options={getLanguages()}
          selectSuggestion='Select Language'
          className='mb-0'
          // disabled={
          //   initialPodcast.status.toLowerCase() ===
          //   PODCAST_STATUS.PUBLISH
          // }
          required
        />
      </div>
    </div>
    <FormikTagWrapper name='tags' label='Tags' placeholder='Enter Tags' />
  </>
);

export default PodcastDetailsStep;
