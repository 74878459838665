import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SvgIcons from '../assets/svg/SvgIcons';
import ButtonWrapper from '../components/form/ButtonWrapper';

const NoNetwork = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.onLine) {
      navigate('/');
    }
  }, []);

  return (
    <div className='d-flex flex-column w-100 align-items-center justify-content-center not-found'>
      <SvgIcons iconType='404' />
      <div className='d-flex flex-column align-items-center justify-content-center gap-3 mt-5'>
        <span className='not-found-title'>Oops! Network Connection Lost.</span>
      </div>
      <ButtonWrapper className='back-to-home mt-4' onClick={() => navigate('/')}>
        Retry
      </ButtonWrapper>
    </div>
  );
};

export default NoNetwork;
