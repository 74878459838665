import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useAppContext } from '../../../../context/App/AppContext';
import LeftPanelDashboardData from './Data/LeftPanelDashboard.Data';
import { LeftPanelDashboardTypes } from './Data/LeftPanelDashboard.Types';
import IconComponent from '../../../../components/Icon/Icon.Component';
import { clearLocalStorage, showToastMessage } from '../../../../utils';
import { TOASTER_STATUS, USER_ROLE } from '../../../../constant';
import { IState } from '../../../../redux/types';
import { getUser } from '../../../../redux/selectors/user';
import { getUserDetailAction, updateUserDetailAction } from '../../../../redux/actions/user';

import Logo from '../../../../assets/images/light/dashboard/Logo.png';
import LogoWhite from '../../../../assets/images/dark/dashboard/LogoWhite.png';

interface IProps {
  role?: string;
}

const LeftPanelDashboard: React.FC<IProps> = ({ role }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = () => {
    if (role === USER_ROLE.ADMIN) {
      navigate('/admin/login');
    } else if (role === USER_ROLE.PODCASTER) {
      navigate('/podcaster/login');
    } else if (role === USER_ROLE.ADVERTISER) {
      navigate('/advertiser/login');
    } else {
      navigate('/login');
    }
  };
  const { shouldCollapseLeftbar, isLightTheme, toggleTheme } = useAppContext();
  const onClickHandler = (type: string): void => {
    if (type === 'logout') {
      clearLocalStorage();
      showToastMessage(TOASTER_STATUS.SUCCESS, 'Logout Successfully.');
      handleLogout();
    } else if (type === 'dark_mode') {
      toggleTheme();
    }
  };

  const isActive = useCallback(
    (path: string) =>
      location.pathname === path || location.pathname.includes(path) ? 'active' : '',
    [location.pathname]
  );
  return (
    <div
      className={`rbt-left-panel popup-dashboardleft-section ${
        shouldCollapseLeftbar ? 'collapsed' : ''
      }`}
    >
      <div className='rbt-default-sidebar'>
        <div className='inner'>
          <div className='header-left d-flex logo-section'>
            {/* <div className='expand-btn-grp'> */}
            {/*    <button */}
            {/*        className={`bg-solid-primary popup-dashboardleft-btn ${ */}
            {/*            !mobile ? "collapsed" : "" */}
            {/*        }`} */}
            {/*        onClick={() => setMobile(!mobile)} */}
            {/*        type='button' */}
            {/*    > */}
            {/*        <i className='feather-sidebar left'/> */}
            {/*    </button> */}
            {/* </div> */}
            <div className='logo'>
              <Link to='/dashboard'>
                {isLightTheme ? (
                  <img src={LogoWhite} alt='avatar' className='mr--5' />
                ) : (
                  <img src={Logo} alt='avatar' className='mr--5' />
                )}
              </Link>
            </div>
          </div>
          <div className='content-item-content'>
            <div className='rbt-default-sidebar-wrapper'>
              <nav className='mainmenu-nav'>
                {LeftPanelDashboardData.map((item: LeftPanelDashboardTypes) => (
                  <div key={item.id}>
                    <div className='title mt--20'>{item.text}</div>
                    <ul className='dashboard-mainmenu rbt-default-sidebar-list'>
                      {item.menu.map((menuItem) => {
                        const iconPath = isLightTheme
                          ? `dashboard/dark/${menuItem.icon}.svg`
                          : `dashboard/light/${menuItem.icon}.svg`;
                        if (menuItem.id === 'logout' && menuItem.isButton)
                          return (
                            <li>
                              <button
                                type='button'
                                className='menu-item'
                                onClick={() => onClickHandler(menuItem.id)}
                              >
                                <IconComponent path={iconPath} name={menuItem.label} />
                                <span>{menuItem.label}</span>
                              </button>
                            </li>
                          );
                        if (menuItem.id === 'dark_mode' && menuItem.isButton)
                          return (
                            <li>
                              <div className='menu-item'>
                                <label className='d-flex align-items-center' htmlFor='switch-mood'>
                                  <span className='switch'>
                                    <input
                                      type='checkbox'
                                      id='switch-mood'
                                      onClick={() => toggleTheme()}
                                    />
                                    <span className='slider' />
                                  </span>
                                  <span>{isLightTheme ? 'Light mode' : menuItem.label}</span>
                                </label>
                              </div>
                            </li>
                          );
                        return (
                          <li key={menuItem.id}>
                            {!menuItem.isButton && (
                              <Link className={`${isActive(menuItem.link!)}`} to={menuItem.link!}>
                                <IconComponent path={iconPath} name={menuItem.label} />
                                <span>{menuItem.label}</span>
                              </Link>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ))}
                <div className='rbt-sm-separator' />
              </nav>
            </div>
          </div>
        </div>
        <div className='subscription-box'>
          <div className='inner'>
            <div className='btn-part'>
              <Link to='/dashboard/pricing' className='btn-default'>
                Upgrade To Pro
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  user: getUser(state),
});

const mapDispatchToProps = {
  updateUserDetailAction,
  getUserDetailAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(LeftPanelDashboard);
