import React, { useState, useEffect, ChangeEvent } from 'react';

import FormGroupWrapper from '../form/FormGroupWrapper';
import SearchIcon from '../../assets/svg/SearchIcon';

import './index.scss';
import { showToastMessage, validateHtmlTag } from '../../utils';
import { TOASTER_STATUS } from '../../constant';

interface IProps {
  searchValue: string;
  handleSearch: (value: string) => void; // eslint-disable-line
}

const SearchInputWrapper: React.FC<IProps> = ({ searchValue, handleSearch }) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(searchValue);
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget?.value;
    if (value.trim()?.length <= 255) {
      setValue(value);
      if (validateHtmlTag(value)) {
        showToastMessage(TOASTER_STATUS.ERROR, 'For security reasons, HTML tags are not allowed');
      } else {
        handleSearch(value);
      }
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, 'Maximum 255 characters allowed');
    }
  };

  useEffect(() => {
    if (!searchValue) {
      setValue('');
      handleSearch('');
    }
  }, [searchValue]);

  return (
    <div className='search-container'>
      <div className='d-md-flex d-block search-bar'>
        <FormGroupWrapper
          hasLabel={false}
          placeholder='Search...'
          type='text'
          autoComplete='off'
          name='search'
          onChange={handleChange}
          value={value}
          error={undefined}
          elementRender={<SearchIcon />}
        />
      </div>
    </div>
  );
};

export default SearchInputWrapper;
