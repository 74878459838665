import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import RemoveIcon from '../../assets/svg/RemoveIcon';
import SvgIcons from '../../assets/svg/SvgIcons';
import { CLOUDINARY_URL, DOMAIN_URL } from '../../clientConfig';
import {
  APP_HOST,
  PLAN_ACTIONS,
  PODCAST_STATUS,
  TOASTER_STATUS,
} from '../../constant';
import APP_ROUTES, { PODCASTER_APP_ROUTES } from '../../constant/appRoute';
import { OPEN_EDITOR } from '../../constant/modal';
import { getUserDetailAction } from '../../redux/actions/user';
import { getUser } from '../../redux/selectors/user';
import { IState } from '../../redux/types';
import { exportAudio } from '../../services/podcaster/AudioEditor';
import {
  exportAudioWithAudioMark,
  updateEnhanceDuration,
} from '../../services/podcaster/Episode';
import { paymentPlanAction } from '../../services/podcaster/Subscription';
import { IUser } from '../../types';
import {
  getLocalStorage,
  getMinutesFromSeconds,
  setLocalStorage,
  showToastMessage,
} from '../../utils';
import {
  EnhancementUrl,
  audioStatus,
  getEnhancePresignedUrl,
  removeNoise,
} from '../Action/EnhanceAudioAudo';
import SelectWrapper from '../Dropdown/SelectWrapper';
import ButtonWrapper from '../form/ButtonWrapper';
import ModalWrapper from '../form/ModalWrapper';
import Loader from '../Loader/Loader';
import DeleteModal from '../Modal/DeleteModal';
import TooltipWrapper from '../Tooltip/TooltipWrapper';
import AudioWave from './AudioWave';

type AudioContextType = string;

interface IProps {
  setStage: (val: number) => void;
  setEnhancedAudio: React.Dispatch<React.SetStateAction<AudioContextType>>;
  uploadedFile: string;
  setUploadedFile: (val: string) => void;
  isEnhancing: boolean;
  setIsEnhancing: React.Dispatch<React.SetStateAction<boolean>>;
  setDownloadPath?: React.Dispatch<React.SetStateAction<string>>;
  handlePageLoading?: (value: boolean) => void;
  status?: string;
  formInstance?: any;
  handleClick?: (value: any, redirectOn?: any) => void;
  initialUploadedAudio?: any;
  isAudioEdited?: boolean;
  id?: string | null;
  audioName?: string | null;
  redirectToAudioEditor?: (id: string, redirectOn?: any) => void;
  setIsProcessing: (value: boolean) => void;
  user: IUser;
  refetchLimits: () => void;
  enhanceAudioDuration: number;
  getUserDetailAction: (host: string) => void;
  planUpdated: boolean;
}

const EnhanceAudio: React.FC<IProps> = ({
  setStage,
  setEnhancedAudio,
  uploadedFile,
  setUploadedFile,
  isEnhancing,
  setIsEnhancing,
  setDownloadPath,
  handlePageLoading,
  status,
  formInstance,
  handleClick,
  initialUploadedAudio,
  isAudioEdited,
  id,
  audioName,
  redirectToAudioEditor,
  setIsProcessing,
  user,
  enhanceAudioDuration,
  refetchLimits,
  getUserDetailAction,
  planUpdated,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isOpenEditor, setIsOpenEditor] = useState<boolean>(false);
  const [audioLoading, setAudioLoading] = useState<boolean>(true);
  const [enhanceMessage, setEnhanceMessage] = useState<string>('');
  const [upgradePlanPopup, setUpgradePlanPopup] = useState<boolean>(false);
  const [paymentIntent, setPaymentIntent] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [exportAudioValue, setExportAudioValue] = useState<any>(null);
  const [isRedirectOnSubscription, setIsRedirectOnSubscription] = useState<boolean>(false);
  const [isPlanUpdated, setIsPlanUpdated] = useState<boolean>(planUpdated);
  const handleLoading = (value: boolean) => setIsLoading(value);

  const handleGetStatus = useCallback(async (jobId: string, data: EnhancementUrl) => {
    try {
      const response = await audioStatus(jobId);
      setEnhanceMessage(`Removing Noise (${response?.data?.percent ?? 100}%)`);
      if (response.data?.state !== 'failed') {
        if (response.data.downloadPath) {
          updateEnhanceDuration(Number(audioRef?.current?.duration)).then(() => refetchLimits());
          setEnhancedAudio(`${CLOUDINARY_URL}${data.enhancedAudioPath}`);
          if (setDownloadPath) setDownloadPath(`${CLOUDINARY_URL}${data.enhancedAudioPath}` || '');
          setIsEnhancing(false);
          setIsProcessing(false);
          setStage(4);
          if (handlePageLoading) {
            handlePageLoading(false);
          }
        } else {
          // If the processing is not complete, retry after a delay
          setTimeout(() => {
            handleGetStatus(jobId, data);
          }, 2000);
        }
      } else {
        showToastMessage(TOASTER_STATUS.ERROR, response.data?.reason ?? 'Something went wrong');
        setIsEnhancing(false);
        setIsProcessing(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleRemoveNoise = useCallback(async (data: EnhancementUrl) => {
    setEnhanceMessage('Removing Noise');
    try {
      // Call the updated removeNoise function
      const response = await removeNoise(data.presignedUrlForInput, data.presignedUrlForOutput);
      console.log('Response from removeNoise:', response);
      const urlMatch = response.match(/https?:\/\/[^\s]+\.(mp3|wav)[^\s]*/);
      const baseUrl = urlMatch ? urlMatch[0].split('?')[0] : '';
      // Once the processed audio is retrieved, update the state
      setEnhancedAudio(baseUrl);
      if (setDownloadPath) {
        setDownloadPath(baseUrl);
      }
      setIsEnhancing(false);
      setIsProcessing(false);
      setStage(4);
      if (handlePageLoading) {
        handlePageLoading(false);
      }
    } catch (error) {
      console.error('Noise removal failed:', error);
      showToastMessage(TOASTER_STATUS.ERROR, 'Something went wrong!');
      setIsEnhancing(false);
      setIsProcessing(false);
    }
  }, []);
  

  const handleEnhancementUrl = (audio: EnhancementUrl) => {
    handleRemoveNoise(audio);
  };

  // Function to upload either recorded audio or uploaded audio file
  const uploadFileOrRecordFile = async () => {
    if (!isEnhancing) {
      setIsEnhancing(true);
      setIsProcessing(true);
      setEnhanceMessage('Please wait');

      await getEnhancePresignedUrl(
        uploadedFile?.replace(CLOUDINARY_URL ?? '', ''),
        handleEnhancementUrl,
        () => {
          setIsEnhancing(false);
          setIsProcessing(false);
        }
      );
    }
  };

  // Function to handle removing the enhanced audio
  const handleRemoveAudio = () => {
    if (getLocalStorage(PLAN_ACTIONS.DOWNLOAD_RECORDED_FILE))
      localStorage.removeItem(PLAN_ACTIONS.DOWNLOAD_RECORDED_FILE);
    setEnhancedAudio('');
    setUploadedFile('');
    setStage(0);

    if (isAudioEdited) {
      navigate(location.pathname, {
        state: { podcastId: location.state.podcastId },
      });
    }
  };

  const onCancelOpenEditor = () => setIsOpenEditor(false);

  const onOpenEditor = () => {
    const pathNameList = location.pathname.split('/');

    if (pathNameList.length === 5 && pathNameList.at(-1) === 'edit') {
      if (initialUploadedAudio === uploadedFile && redirectToAudioEditor) {
        redirectToAudioEditor(pathNameList.at(-2) ?? '', {
          isOpenEditor: true,
          isRedirectOnSubscription: false,
        });
      } else {
        setIsOpenEditor(true);
      }
    } else {
      setIsOpenEditor(true);
    }
  };

  const onDownload = () => {
    if (initialUploadedAudio === uploadedFile && redirectToAudioEditor) {
      const updatePlanWindow = window.open(
        `${PODCASTER_APP_ROUTES.ROOT}/${APP_ROUTES.SUBSCRIPTION}`,
        '_blank'
      );
      window.addEventListener('message', (event) => {
        if (event.data === 'planUpdated') {
          setIsPlanUpdated(true);
          setUpgradePlanPopup(false);
          getUserDetailAction(APP_HOST.PODCASTER);
          updatePlanWindow?.close();
        }
      });
    } else {
      setIsRedirectOnSubscription(true);
    }
  };

  const onOpenEditorOk = () => {
    if (formInstance) {
      if (Object.keys(formInstance.errors).length === 0 && handleClick) {
        handleClick(
          { ...formInstance.values, status: 'draft' },
          { isOpenEditor: true, isRedirectOnSubscription: false }
        );
      } else {
        formInstance.handleSubmit();
        showToastMessage(TOASTER_STATUS.ERROR, 'Please fill all required fields');
      }
      setIsOpenEditor(false);
    }
  };

  const onExportAudio = async (value: any, downloadWithAudioMark = false) => {
    if (!isExporting) {
      if (
        !isPlanUpdated &&
        !downloadWithAudioMark &&
        !user?.activePlanUuidAndEndDate?.activePlanAmount &&
        !getLocalStorage(PLAN_ACTIONS.DOWNLOAD_RECORDED_FILE)
      ) {
        setUpgradePlanPopup(true);
        setExportAudioValue(value);
      } else if (downloadWithAudioMark) {
        const objectKey = uploadedFile?.split('podcast-episodes/')[1];
        try {
          setIsExporting(true);
          setUpgradePlanPopup(false);
          setUpgradePlanPopup(false);
          const checkPlan =
            !user?.activePlanUuidAndEndDate?.activePlanAmount &&
            getLocalStorage(PLAN_ACTIONS.DOWNLOAD_RECORDED_FILE);
          const responseData = await exportAudioWithAudioMark(objectKey, !checkPlan);
          if (responseData?.success) {
            if (responseData?.result?.audioMarkStatus === 'SUCCESS') {
              const downloadUrl = `${CLOUDINARY_URL}${responseData?.result?.fileUrl}`;
              if (id && audioName) {
                exportAudio(
                  value.format,
                  value.quality,
                  id,
                  downloadUrl,
                  audioName,
                  (value: boolean) => setIsExporting(false)
                );
              }
            }
          } else {
            setIsExporting(false);
            showToastMessage(
              TOASTER_STATUS.ERROR,
              responseData?.error?.txt || 'Something Went Wrong!'
            );
          }
        } catch (error: any) {
          setIsExporting(false);
          showToastMessage(
            TOASTER_STATUS.ERROR,
            error?.response?.data?.result?.errorMessage || 'Something Went Wrong!'
          );
        }
      } else if (id && audioName) {
        setIsExporting(true);
        exportAudio(value.format, value.quality, id, uploadedFile, audioName, (value: boolean) =>
          setIsExporting(false)
        );
      }
    }
  };

  useEffect(() => {
    if (paymentIntent) {
      const paymentWindow: any = window.open(
        `${DOMAIN_URL}podcaster/${APP_ROUTES.PAYMENT}`,
        '_blank',
        'width=768,height=600'
      );

      paymentWindow.paymentIntent = paymentIntent;

      // Listen for messages from the payment window
      window.addEventListener('message', (event) => {
        if (event.data === 'paymentSuccess' && event.source === paymentWindow) {
          paymentWindow?.close();
          setLocalStorage(PLAN_ACTIONS.DOWNLOAD_RECORDED_FILE, true);
          setUpgradePlanPopup(false);
          onExportAudio(exportAudioValue);
        }
      });
    }
  }, [paymentIntent]);

  const handlePaymentIntent = (value: any) => setPaymentIntent(value);
  const handlePaymentPlanAction = (planAction: any) => {
    paymentPlanAction(planAction, 0, 0, handleLoading, handlePaymentIntent);
  };

  return (
    <div className='row'>
      <div className='col-lg-12  form-style'>
        <span>Audio</span>
        <div className='big-height record-audio mb-40 position-relative'>
          {/* eslint-disable-next-line */}
          <audio
            id='audio-player'
            ref={audioRef}
            src={uploadedFile}
            hidden
            crossOrigin='anonymous'
          />
          <img
            src={`${CLOUDINARY_URL}Images/recoding-ready.png`}
            alt='recoding-ready'
            className='img-fluid d-block mx-auto'
          />
          <AudioWave
            classString='enhance-wave'
            link={uploadedFile}
            audioLoading={audioLoading}
            setAudioLoading={setAudioLoading}
          />
          {status?.toLowerCase() !== PODCAST_STATUS.PUBLISH &&
            !isEnhancing &&
            !isAudioEdited &&
            !audioLoading && (
              // eslint-disable-next-line
              <div className='edit-btn' onClick={onOpenEditor}>
                <div className='open-editor'>
                  <SvgIcons iconType='edit-btn' /> Open Editor
                </div>
              </div>
            )}
          {status?.toLowerCase() !== PODCAST_STATUS.PUBLISH && isAudioEdited && (
            <div className='export-audio-btn'>
              <SelectWrapper
                items={[]}
                onSelect={onExportAudio}
                placeholder='Export As'
                hasIcon
                IconName={SvgIcons}
                iconType='icon-export'
                isExportAs
                formatList={['WAV', 'MP3', 'AIFF', 'FLAC', 'AAC', 'WMA']}
                qualityList={['64 kbps', '128 kbps', '160 kbps', '320 kbps']}
                isExporting={!upgradePlanPopup && isExporting}
              />
            </div>
          )}
          {!isEnhancing && status?.toLowerCase() !== PODCAST_STATUS.PUBLISH && !audioLoading && (
            // eslint-disable-next-line
            <span className='close-podcast' onClick={() => setDeleteModal(true)}>
              <RemoveIcon />
            </span>
          )}
          {status?.toLowerCase() !== PODCAST_STATUS.PUBLISH && isAudioEdited && (
            <div className='export-audio-btn'>
              <SelectWrapper
                items={[]}
                onSelect={onExportAudio}
                placeholder='Export As'
                hasIcon
                IconName={SvgIcons}
                iconType='icon-export'
                isExportAs
                formatList={['WAV', 'MP3', 'AIFF', 'FLAC', 'AAC', 'WMA']}
                qualityList={['64 kbps', '128 kbps', '160 kbps', '320 kbps']}
                isExporting={!upgradePlanPopup && isExporting}
              />
            </div>
          )}
          {!isEnhancing && status?.toLowerCase() !== PODCAST_STATUS.PUBLISH && !audioLoading && (
            // eslint-disable-next-line
            <span className='close-podcast' onClick={() => setDeleteModal(true)}>
              <RemoveIcon />
            </span>
          )}
        </div>

        {/* Button to enhance the audio */}
        {!isAudioEdited &&
          status?.toLowerCase() !== PODCAST_STATUS.PUBLISH &&
          uploadedFile &&
          !audioLoading && (
            <div className='enhance-btn'>
              {audioRef.current && audioRef.current?.duration <= enhanceAudioDuration ? (
                <TooltipWrapper
                  tooltip={`Every time you use the 'Enhance Audio' feature, it will reduce your enhancement limit.`}
                  overlayProps={{ placement: 'bottom' }}
                  showTooltip={!isEnhancing}
                >
                  <button
                    type='button'
                    disabled={isEnhancing}
                    className='btn-primary btn-style btn-bg audio-enh'
                    onClick={uploadFileOrRecordFile}
                  >
                    {isEnhancing ? (
                      <div className='d-flex align-items-center justify-content-center gap-3'>
                        <Loader />
                        {enhanceMessage}
                      </div>
                    ) : (
                      'Enhance Audio'
                    )}
                  </button>
                </TooltipWrapper>
              ) : (
                <TooltipWrapper
                  tooltip={`You don't have enough limit for enhancing audio. Remaining limit ${getMinutesFromSeconds(
                    enhanceAudioDuration
                  )
                    .replace('min', 'Minutes')
                    .replace('sec', 'Seconds')}`}
                  overlayProps={{ placement: 'bottom' }}
                >
                  <button type='button' disabled className='btn-primary btn-style btn-bg audio-enh'>
                    Enhance Audio
                  </button>
                </TooltipWrapper>
              )}
            </div>
          )}
      </div>
      {/* eslint-disable-next-line */}
      <DeleteModal
        show={deleteModal}
        closeModal={setDeleteModal}
        loading={false}
        message='Do you really want to remove this audio?'
        handleDeleteData={handleRemoveAudio}
      />
      {isOpenEditor && (
        <ModalWrapper
          size='sm'
          show={isOpenEditor}
          body={{
            title: OPEN_EDITOR.BODY.title,
            content: OPEN_EDITOR.BODY.content,
          }}
          button1={{
            children: OPEN_EDITOR.BUTTON1_CHILDREN,
            onClick: onCancelOpenEditor,
          }}
          button2={{
            children: OPEN_EDITOR.BUTTON2_CHILDREN,
            onClick: onOpenEditorOk,
          }}
          handleClose={onCancelOpenEditor}
          className='open-editor-modal'
        />
      )}
      {isRedirectOnSubscription && (
        <ModalWrapper
          size='sm'
          show={isRedirectOnSubscription}
          body={{
            title: '',
            content:
              'Your work will be saved as a draft, and you will be redirected to the subscription page.',
          }}
          button1={{
            children: OPEN_EDITOR.BUTTON1_CHILDREN,
            onClick: onCancelOpenEditor,
          }}
          button2={{
            children: OPEN_EDITOR.BUTTON2_CHILDREN,
            onClick: onOpenEditorOk,
          }}
          handleClose={onCancelOpenEditor}
          className='open-editor-modal'
        />
      )}

      {upgradePlanPopup && (
        <ModalWrapper
          size='lg'
          show={upgradePlanPopup}
          handleClose={() => {
            if (!isExporting) setUpgradePlanPopup(false);
          }}
          body={{
            title: 'Download Podcast',
            content: 'You need to pay extra or upgrade your plan for download podcast.',
            icon: { Element: SvgIcons, type: 'upgrade-plan' },
          }}
          customElement={
            <div className='audio-mark-btn'>
              <ButtonWrapper
                isDisabled={isExporting}
                onClick={() => {
                  onExportAudio(exportAudioValue, true);
                }}
              >
                {isExporting ? (
                  <div className='d-flex gap-2'>
                    <Loader />
                    Downloading
                  </div>
                ) : (
                  'Download With Audio Mark'
                )}
              </ButtonWrapper>
            </div>
          }
          button1={{
            children: isLoading ? <Loader /> : 'Pay $1 For Download',
            isDisabled: isExporting || isLoading,
            onClick: () => {
              handleLoading(true);
              handlePaymentPlanAction({
                planName: 'Download Podcast',
                amount: 1,
                planFeatures: [],
                period: '',
                actionName: [PLAN_ACTIONS.DOWNLOAD_RECORDED_FILE],
              });
            },
          }}
          button2={{
            isDisabled: isExporting,
            children: 'Upgrade Plan',
            onClick: onDownload,
          }}
          bodyClass='upgrade-plan-modal-body download-popup'
        />
      )}
    </div>
  );
};

EnhanceAudio.defaultProps = {
  setDownloadPath: undefined,
  handlePageLoading: () => {},
  status: '',
  formInstance: undefined,
  handleClick: () => {},
  initialUploadedAudio: undefined,
  isAudioEdited: false,
  id: null,
  audioName: null,
  redirectToAudioEditor: () => {},
};

const mapStateToProps = (state: IState) => ({
  user: getUser(state),
});
const mapDispatchToProps = {
  getUserDetailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnhanceAudio);